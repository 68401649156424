// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerija-js": () => import("./../../../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iz-medija-js": () => import("./../../../src/pages/iz-medija.js" /* webpackChunkName: "component---src-pages-iz-medija-js" */),
  "component---src-pages-izjava-o-pristupacnosti-js": () => import("./../../../src/pages/izjava-o-pristupacnosti.js" /* webpackChunkName: "component---src-pages-izjava-o-pristupacnosti-js" */),
  "component---src-pages-novosti-js": () => import("./../../../src/pages/novosti.js" /* webpackChunkName: "component---src-pages-novosti-js" */),
  "component---src-pages-o-projektu-js": () => import("./../../../src/pages/o-projektu.js" /* webpackChunkName: "component---src-pages-o-projektu-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-yaml-js": () => import("./../../../src/pages/yaml.js" /* webpackChunkName: "component---src-pages-yaml-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

