import React from "react"
import PropTypes from 'prop-types'
// import { MDXProvider } from "@mdx-js/react"
// import { MDXRenderer } from "gatsby-plugin-mdx"
// import Img from "gatsby-image"

// const Galery = ({slike}) =>  (
//    <section className="galerija">
//        <p>Slike koje su u arrayu:{slike} </p>
//        { slike.map((slika,index) => (
//            <p key={index}>Slika= {slika}</p>
//        ))
//     }
//     </section>
// )

// const Galery = ({slike}) =>  (
//    <section className="galerija">
//            <MDXProvider>
      
//     <MDXRenderer>{slike}</MDXRenderer>
//     </MDXProvider>
//     </section>
// )

// import React, {useState, useCallback} from 'react'
// // import {Row, Col} from 'reactstrap';
// import Img from 'gatsby-image'
// import GalleryModal from './GalleryModal';

// const MdxGallery = ({images}) => {
//     const [index, setIndex] = useState(0);
//     const [modalOpen, setModalOpen] = useState(false);
//     const handleClick = useCallback((i) => {
//       setIndex(i);
//       setModalOpen(true);
//     }, [setIndex, setModalOpen]);
//     const prev = useCallback(() => {
//       setIndex(index === 0 ? images.length - 1 : index-1);
//     }, [index, setIndex, images]);
//     const next = useCallback(() => {
//       setIndex(index === images.length - 1 ? 0 : index+1);
//     }, [index, setIndex, images]);
//     return (
//       <Row>
//         <GalleryModal isOpen={modalOpen} setModalOpen={setModalOpen} image={images[index]} prev={prev} next={next} />
//         {images.map((img, i) => {
//           if(process.env.NODE_ENV !== 'production' && !img.src) {
//             // this gets removed in production, so don't worry
//             console.error(`This image is missing "src", you probably messed up the path to it: ${img}`);
//             return <>MISSING IMAGE</>
//           }
//           return (
//             <Col key={i} xs="6" md="3" className="p-2">
//               <button style={{border: 'none', background: 'transparent'}} className="w-100 h-100" onClick={() => handleClick(i)}>
//                 <Img fluid={img.src.childImageSharp.preview} />
//               </button>
//             </Col>
//           );
//         })}
//       </Row>
//     );
//   };
//   export default MdxGallery;

function Gallery({title, alt, className, children}) {
    const klasa='galerija '+className
    return(
    <div className={klasa}>
        {children}
        {/* { React.Children.map(children, (child, index) => (
            <Img fluid={child.src.childImageSharp.fluid} />
            <figure>{child}</figure>
          ))} */}
    </div>
)
}

export default Gallery

Gallery.propTypes = {
    title: PropTypes.string,
    alt: PropTypes.string,
    children: PropTypes.object,
  };